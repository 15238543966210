import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "../firebase";
import "./SendMessage.css";

const SendMessage = () => {
    const [textMessage, setTextMessage] = useState('');
    const [mediaType, setMediaType] = useState('video');
    const [mediaFile, setMediaFile] = useState(null);
    const [mediaPreview, setMediaPreview] = useState(null);
    const [users, setUsers] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttons, setButtons] = useState([{ text: '', url: '' }]);
    const [sendWithMedia, setSendWithMedia] = useState(true);

    const mediaRef = useRef(null);
    const fileInputRef = useRef(null);

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const BOT_TOKEN = "8178061667:AAHE6Et_DxJXNKQ8S8GhoDIywAC5AkNIzPw";

    const fetchUserIds = useCallback(async () => {
        try {
            const usersCollection = collection(db, 'users');
            const userSnapshot = await getDocs(usersCollection);
            const fetchedUsers = userSnapshot.docs.map(doc => ({
                userId: doc.data().userId,
                firstName: doc.data().firstName || '',
                username: doc.data().username || ''
            }));

            setUsers(fetchedUsers);
            setUserIds(fetchedUsers.map(user => user.userId));
        } catch (error) {
            console.error('Error fetching users:', error);
            setStatus('Failed to fetch users. Please try again.');
        }
    }, [db]);

    useEffect(() => {
        fetchUserIds();
    }, [fetchUserIds]);

    const handleTextMessageChange = (e) => setTextMessage(e.target.value);

    const handleMediaTypeChange = (e) => {
        setMediaType(e.target.value);
        setMediaFile(null);
        setMediaPreview(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleMediaChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMediaFile(file);
            const newMediaUrl = URL.createObjectURL(file);
            setMediaPreview(newMediaUrl);
        }
    };

    const handleSendWithMediaChange = (e) => {
        setSendWithMedia(e.target.checked);
    };

    const handleButtonChange = (index, field, value) => {
        const newButtons = [...buttons];
        newButtons[index] = { ...newButtons[index], [field]: value };
        setButtons(newButtons);
    };

    const addButton = () => {
        setButtons([...buttons, { text: '', url: '' }]);
    };

    const removeButton = (index) => {
        setButtons(buttons.filter((_, i) => i !== index));
    };

    useEffect(() => {
        return () => {
            if (mediaPreview) {
                URL.revokeObjectURL(mediaPreview);
            }
        };
    }, [mediaPreview]);

    const createInlineKeyboard = () => {
        const validButtons = buttons.filter(btn => btn.text && btn.url);
        if (validButtons.length === 0) return null;

        return {
            inline_keyboard: [
                validButtons.map(btn => ({
                    text: btn.text,
                    url: btn.url
                }))
            ]
        };
    };

    const replaceVariables = (text, userData) => {
        return text
            .replace(/{name}/g, userData.firstName)
            .replace(/{username}/g, userData.username || userData.firstName);
    };

    const sendMessageAndMediaToTelegram = async () => {
        if (!mediaFile && !textMessage) {
            setStatus('Пожалуйста, выберите медиафайл или введите текст сообщения.');
            return;
        }

        setLoading(true);
        setStatus('Отправка сообщения всем пользователям...');
        let successCount = 0;

        const inlineKeyboard = createInlineKeyboard();

        try {
            for (const user of users) {
                try {
                    let messageSent = false;
                    const personalizedText = replaceVariables(textMessage, {
                        firstName: user.firstName,
                        username: user.username
                    });

                    if (mediaFile) {
                        if (sendWithMedia && personalizedText) {
                            const formData = new FormData();
                            formData.append('chat_id', user.userId);
                            formData.append('caption', personalizedText);

                            if (mediaType === 'video') {
                                formData.append('video', mediaFile);
                                if (inlineKeyboard) {
                                    formData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const response = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendVideo`,
                                    formData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = response.data && response.data.ok;
                            } else {
                                formData.append('photo', mediaFile);
                                if (inlineKeyboard) {
                                    formData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const response = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendPhoto`,
                                    formData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = response.data && response.data.ok;
                            }
                        } else {
                            const mediaFormData = new FormData();
                            mediaFormData.append('chat_id', user.userId);

                            if (mediaType === 'video') {
                                mediaFormData.append('video', mediaFile);
                                if (inlineKeyboard) {
                                    mediaFormData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const videoResponse = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendVideo`,
                                    mediaFormData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = videoResponse.data && videoResponse.data.ok;
                            } else {
                                mediaFormData.append('photo', mediaFile);
                                if (inlineKeyboard) {
                                    mediaFormData.append('reply_markup', JSON.stringify(inlineKeyboard));
                                }
                                const photoResponse = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendPhoto`,
                                    mediaFormData,
                                    { headers: { 'Content-Type': 'multipart/form-data' } }
                                );
                                messageSent = photoResponse.data && photoResponse.data.ok;
                            }

                            if (!sendWithMedia && personalizedText) {
                                const messageData = {
                                    chat_id: user.userId,
                                    text: personalizedText,
                                    parse_mode: 'HTML',
                                    reply_markup: inlineKeyboard
                                };

                                const textResponse = await axios.post(
                                    `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
                                    messageData
                                );
                                messageSent = messageSent && textResponse.data && textResponse.data.ok;
                            }
                        }
                    } else if (personalizedText) {
                        const messageData = {
                            chat_id: user.userId,
                            text: personalizedText,
                            parse_mode: 'HTML',
                            reply_markup: inlineKeyboard
                        };

                        const response = await axios.post(
                            `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`,
                            messageData
                        );
                        messageSent = response.data && response.data.ok;
                    }

                    if (messageSent) {
                        successCount++;
                    }
                } catch (error) {
                    console.error(`Error sending to user ${user.userId}:`, error);
                }
            }

            setStatus(`Успешно отправлено ${successCount} пользователям.`);

            // Reset form
            setTextMessage('');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            setMediaFile(null);
            setMediaPreview(null);
            setButtons([{ text: '', url: '' }]);

            await fetchUserIds();

        } catch (error) {
            console.error('Error in send operation:', error);
            setStatus('Произошла ошибка при отправке сообщений. Пожалуйста, попробуйте снова.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="send-message">
            <h2>Отправка сообщений</h2>

            <div className="users-info">
                <h3>Информация о пользователях</h3>
                <p>Всего пользователей: {users.length}</p>
            </div>

            <div className="message-form">
                <div className="text-input-container">
                    <p className="help-text">
                        Используйте {"{name}"} для вставки имени пользователя и {"{username}"} для ника
                    </p>
                    <textarea
                        value={textMessage}
                        onChange={handleTextMessageChange}
                        placeholder="Введите сообщение"
                        className="message-textarea"
                        disabled={loading}
                    />
                </div>

                <div className="media-type-selector">
                    <label>
                        <input
                            type="radio"
                            value="video"
                            checked={mediaType === 'video'}
                            onChange={handleMediaTypeChange}
                            disabled={loading}
                        />
                        Видео
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="photo"
                            checked={mediaType === 'photo'}
                            onChange={handleMediaTypeChange}
                            disabled={loading}
                        />
                        Фото
                    </label>
                </div>

                <div className="file-input-container">
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept={mediaType === 'video' ? "video/*" : "image/*"}
                        onChange={handleMediaChange}
                        disabled={loading}
                        className="file-input"
                    />
                    {mediaFile && (
                        <div className="media-options">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={sendWithMedia}
                                    onChange={handleSendWithMediaChange}
                                    disabled={loading}
                                />
                                Отправить текст как подпись к {mediaType === 'video' ? 'видео' : 'фото'}
                            </label>
                        </div>
                    )}
                </div>

                <div className="buttons-section">
                    <h3>Кнопки с URL</h3>
                    {buttons.map((button, index) => (
                        <div key={index} className="button-inputs">
                            <input
                                type="text"
                                value={button.text}
                                onChange={(e) => handleButtonChange(index, 'text', e.target.value)}
                                placeholder="Текст кнопки"
                                className="button-input"
                                disabled={loading}
                            />
                            <input
                                type="url"
                                value={button.url}
                                onChange={(e) => handleButtonChange(index, 'url', e.target.value)}
                                placeholder="URL кнопки (https://...)"
                                className="button-input"
                                disabled={loading}
                            />
                            <button
                                onClick={() => removeButton(index)}
                                className="remove-button"
                                disabled={loading || buttons.length === 1}
                            >
                                Удалить
                            </button>
                        </div>
                    ))}
                    <button
                        onClick={addButton}
                        className="add-button"
                        disabled={loading}
                    >
                        Добавить кнопку
                    </button>
                </div>

                {mediaPreview && (
                    <div className="media-preview">
                        {mediaType === 'video' ? (
                            <video
                                ref={mediaRef}
                                controls
                                src={mediaPreview}
                                width="300"
                            />
                        ) : (
                            <img
                                src={mediaPreview}
                                alt="Preview"
                                style={{ maxWidth: '300px' }}
                            />
                        )}
                    </div>
                )}

                <button
                    onClick={sendMessageAndMediaToTelegram}
                    disabled={loading || (!mediaFile && !textMessage)}
                    className={`send-button ${loading ? 'loading' : ''}`}
                >
                    {loading ? 'Отправка...' : 'Отправить'}
                </button>

                {status && (
                    <div className={`status-message ${status.includes('Failed') ? 'error' : 'success'}`}>
                        <pre>{status}</pre>
                    </div>
                )}
            </div>

            {loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
};

export default SendMessage;