import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import axios from 'axios';
import './UpdateBot.css';

const API_URL = 'https://chat.bratanchatbot.xyz';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: false
});
axiosInstance.interceptors.request.use(config => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
}, error => {
    return Promise.reject(error);
});
// Константы для типов контента
const contentTypes = [
    'preview_welcome',
];

const sectionsWithUrl = [
    'preview_welcome',
];

// Начальное состояние
const initialState = {
    contents: {},
    loading: false,
    error: null,
    initialized: false
};

// Редьюсер для управления состоянием
function contentReducer(state, action) {
    switch (action.type) {
        case 'SET_BUTTONS':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: {
                        ...state.contents[action.contentType],
                        buttons: action.payload
                    }
                }
            };
        case 'INIT_SUCCESS':
            return { ...state, initialized: true };
        case 'SET_CONTENT':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: action.payload
                }
            };
        case 'UPDATE_CONTENT_TEXT':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: {
                        ...state.contents[action.contentType],
                        text: action.payload
                    }
                }
            };
        case 'UPDATE_CONTENT_URL':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: {
                        ...state.contents[action.contentType],
                        url: action.payload
                    }
                }
            };
        case 'UPDATE_SECONDARY_URL':
            return {
                ...state,
                contents: {
                    ...state.contents,
                    [action.contentType]: {
                        ...state.contents[action.contentType],
                        secondaryUrl: action.payload
                    }
                }
            };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_ERROR':
            return { ...state, error: action.payload };
        default:
            return state;
    }
}

// Компонент диалога подтверждения
const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, message, confirmText }) => {
    if (!isOpen) return null;

    return (
        <div className="dialog-overlay">
            <div className="dialog-content">
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="dialog-buttons">
                    <button onClick={onClose} className="cancel-button">Cancel</button>
                    <button onClick={onConfirm} className="confirm-button">{confirmText}</button>
                </div>
            </div>
        </div>
    );
};

// Компонент уведомления об успехе
const SuccessAlert = ({ isVisible, message }) => {
    if (!isVisible) return null;

    return (
        <div className="success-alert">
            <div className="success-content">
                <span className="success-icon">✓</span>
                {message}
            </div>
        </div>
    );
};
const ButtonEditor = ({ buttons, onChange, disabled }) => {
    return (
        <div className="buttons-section">
            <h3>Button Settings</h3>
            <div className="buttons-container">
                {/* Primary Button */}
                <div className="button-group">
                    <h4>First Button</h4>
                    <div className="input-group">
                        <label>Button Text:</label>
                        <input
                            type="text"
                            value={buttons.primary.text}
                            onChange={(e) => onChange({
                                ...buttons,
                                primary: { ...buttons.primary, text: e.target.value }
                            })}
                            placeholder="Enter button text"
                            className="text-input"
                            disabled={disabled}
                        />
                    </div>
                    <div className="input-group">
                        <label>Button URL:</label>
                        <input
                            type="text"
                            value={buttons.primary.url}
                            onChange={(e) => onChange({
                                ...buttons,
                                primary: { ...buttons.primary, url: e.target.value }
                            })}
                            placeholder="Enter URL"
                            className="url-input"
                            disabled={disabled}
                        />
                    </div>
                </div>

                {/* Secondary Button */}
                <div className="button-group">
                    <h4>Second Button</h4>
                    <div className="input-group">
                        <label>Button Text:</label>
                        <input
                            type="text"
                            value={buttons.secondary.text}
                            onChange={(e) => onChange({
                                ...buttons,
                                secondary: { ...buttons.secondary, text: e.target.value }
                            })}
                            placeholder="Enter button text"
                            className="text-input"
                            disabled={disabled}
                        />
                    </div>
                    <div className="input-group">
                        <label>Button URL:</label>
                        <input
                            type="text"
                            value={buttons.secondary.url}
                            onChange={(e) => onChange({
                                ...buttons,
                                secondary: { ...buttons.secondary, url: e.target.value }
                            })}
                            placeholder="Enter URL"
                            className="url-input"
                            disabled={disabled}
                        />
                    </div>
                </div>

                {/* Tertiary Button */}
                <div className="button-group">
                    <h4>Third Button</h4>
                    <div className="input-group">
                        <label>Button Text:</label>
                        <input
                            type="text"
                            value={buttons.tertiary.text}
                            onChange={(e) => onChange({
                                ...buttons,
                                tertiary: { ...buttons.tertiary, text: e.target.value }
                            })}
                            placeholder="Enter button text"
                            className="text-input"
                            disabled={disabled}
                        />
                    </div>
                    <div className="input-group">
                        <label>Button URL:</label>
                        <input
                            type="text"
                            value={buttons.tertiary.url}
                            onChange={(e) => onChange({
                                ...buttons,
                                tertiary: { ...buttons.tertiary, url: e.target.value }
                            })}
                            placeholder="Enter URL"
                            className="url-input"
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// Компонент редактора текста
const TextContentEditor = ({ value, onChange, disabled }) => {
    return (
        <div className="text-editor">
            <textarea
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                placeholder="Enter content text..."
                rows={6}
            />
        </div>
    );
};


const VideoPreview = ({ contentType }) => {
    const [currentVideo, setCurrentVideo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadVideo = async () => {
            try {
                setLoading(true);
                setError(null);

                const videoResponse = await axiosInstance.get(`/api/get-video/${contentType}`, {
                    responseType: 'blob'
                });

                if (videoResponse.data.size > 0) {
                    const videoUrl = URL.createObjectURL(videoResponse.data);
                    setCurrentVideo(videoUrl);
                }
            } catch (err) {
                console.error('Video Loading Error:', err);
                setError('No video uploaded yet');
            } finally {
                setLoading(false);
            }
        };

        loadVideo();

        return () => {
            if (currentVideo) {
                URL.revokeObjectURL(currentVideo);
            }
        };
    }, [contentType]);

    if (loading) {
        return <div className="video-loading">Loading video...</div>;
    }

    return (
        <div className="video-section">
            {error && (
                <div className="video-error" style={{ color: 'red', marginTop: '10px' }}>
                    {error}
                </div>
            )}

            {currentVideo && (
                <div className="video-preview">
                    <video
                        controls
                        src={currentVideo}
                        width="300"
                        style={{
                            marginTop: '10px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                    />
                </div>
            )}
        </div>
    );
};

// Вспомогательные функции
const validateFileSize = (file) => {
    const maxSize = 100 * 1024 * 1024;
    if (file.size > maxSize) {
        return {
            valid: false,
            error: 'File size exceeds 100MB limit.'
        };
    }
    if (!file.type.startsWith('video/')) {
        return {
            valid: false,
            error: 'Only video files are allowed.'
        };
    }
    return { valid: true };
};

const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// Основной компонент UpdateBot
const UpdateBot = () => {
    const [state, dispatch] = useReducer(contentReducer, initialState);
    const [selectedContentType, setSelectedContentType] = useState('preview_welcome');
    const [videoFile, setVideoFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [buttons, setButtons] = useState({
        primary: { text: '🎮 ZAISTI CIA 🎮', url: '' },
        secondary: { text: '💰 UZDIRBTI 💰', url: '' },
        tertiary: { text: '🌐 ZAISTI ONLINE 🌐', url: '' }
    });
    const initialized = useRef(false);

    const fetchButtons = useCallback(async () => {
        try {
            const response = await axiosInstance.get(`/api/get-buttons/${selectedContentType}`);
            setButtons(response.data);
        } catch (error) {
            console.error('Error fetching buttons:', error);
            dispatch({
                type: 'SET_ERROR',
                payload: 'Failed to fetch buttons configuration'
            });
        }
    }, [selectedContentType]);

    // Инициализация
    useEffect(() => {
        const init = async () => {
            if (!initialized.current) {
                try {
                    await axiosInstance.get('/api/get-text/preview_welcome');
                    dispatch({type: 'INIT_SUCCESS'});
                    initialized.current = true;
                } catch (error) {
                    console.error('Initialization error:', error);
                    dispatch({
                        type: 'SET_ERROR',
                        payload: 'Unable to connect to server. Please check your connection.'
                    });
                }
            }
        };
        init();
    }, []);

    // Загрузка контента
    const fetchContent = useCallback(async (contentType) => {
        if (!state.initialized || state.contents[contentType]) return;

        dispatch({type: 'SET_LOADING', payload: true});

        try {
            const promises = [
                await axiosInstance.get(`/api/get-text/${contentType}`),
                sectionsWithUrl.includes(contentType)
                    ? axiosInstance.get(`/api/get-url/${contentType}`)
                        .catch(() => ({data: {url: ''}}))
                    :  Promise.resolve({data: {url: ''}})
            ];

            if (contentType === 'new_player_bonus') {
                promises.push(
                    await axiosInstance.get(`/api/get-secondary-url/${contentType}`)
                        .catch(() => ({data: {secondaryUrl: ''}}))
                );
            }

            const [textResponse, urlResponse, secondaryUrlResponse] = await Promise.all(promises);

            dispatch({
                type: 'SET_CONTENT',
                contentType,
                payload: {
                    text: textResponse.data.text || '',
                    url: urlResponse.data.url || '',
                    secondaryUrl: secondaryUrlResponse?.data.secondaryUrl || ''
                }
            });
        } catch (error) {
            console.error(`Error fetching content for ${contentType}:`, error);
            dispatch({
                type: 'SET_ERROR',
                payload: `Failed to fetch content for ${contentType}`
            });
        } finally {
            dispatch({type: 'SET_LOADING', payload: false});
        }
    }, [state.initialized]);

    // Эффекты
    useEffect(() => {
        if (videoPreview) {
            URL.revokeObjectURL(videoPreview);
            setVideoPreview(null);
        }
        setVideoFile(null);
    }, [selectedContentType]);

    useEffect(() => {
        if (state.initialized && !state.contents[selectedContentType]) {
            fetchContent(selectedContentType);
        }
    }, [selectedContentType, state.initialized, fetchContent]);

    // Очистка
    useEffect(() => {
        return () => {
            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }
        };
    }, [videoPreview]);
    // Добавляем загрузку кнопок при инициализации
    useEffect(() => {
        if (state.initialized) {
            fetchButtons();
        }
    }, [state.initialized, fetchButtons]);

// Обновление кнопок
    const handleButtonsUpdate = async () => {
        try {
            dispatch({ type: 'SET_LOADING', payload: true });
            await axiosInstance.post(`/api/update-buttons/${selectedContentType}`, buttons);

            dispatch({
                type: 'SET_BUTTONS',
                contentType: selectedContentType,
                payload: buttons
            });

            setShowSuccessAlert(true);
            setTimeout(() => setShowSuccessAlert(false), 3000);
        } catch (error) {
            dispatch({
                type: 'SET_ERROR',
                payload: 'Failed to update buttons'
            });
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };
    // Обработчики
    const handleTextUpdate = useCallback(async (contentType, newText) => {
        try {
            dispatch({type: 'SET_LOADING', payload: true});
            await axiosInstance.post(`/api/update-text/${contentType}`, {text: newText});

            dispatch({
                type: 'UPDATE_CONTENT_TEXT',
                contentType: contentType,
                payload: newText
            });

            setShowSuccessAlert(true);
            setTimeout(() => setShowSuccessAlert(false), 3000);
        } catch (error) {
            dispatch({
                type: 'SET_ERROR',
                payload: 'Failed to update text content'
            });
        } finally {
            dispatch({type: 'SET_LOADING', payload: false});
        }
    }, []);

    const handleUrlUpdate = useCallback(async (contentType, newUrl, isSecondary = false) => {
        try {
            dispatch({type: 'SET_LOADING', payload: true});

            const endpoint = isSecondary
                ? `/api/update-secondary-url/${contentType}`
                : `/api/update-url/${contentType}`;

            await axiosInstance.post(endpoint, {
                [isSecondary ? 'secondaryUrl' : 'url']: newUrl
            });

            dispatch({
                type: isSecondary ? 'UPDATE_SECONDARY_URL' : 'UPDATE_CONTENT_URL',
                contentType: contentType,
                payload: newUrl
            });

            setShowSuccessAlert(true);
            setTimeout(() => setShowSuccessAlert(false), 3000);
        } catch (error) {
            dispatch({
                type: 'SET_ERROR',
                payload: `Failed to update ${isSecondary ? 'secondary ' : ''}URL`
            });
        } finally {
            dispatch({type: 'SET_LOADING', payload: false});
        }
    }, []);

    const handleVideoChange = useCallback((e) => {
        const file = e.target.files[0];
        if (file) {
            const validation = validateFileSize(file);
            if (!validation.valid) {
                dispatch({
                    type: 'SET_ERROR',
                    payload: validation.error
                });
                e.target.value = '';
                return;
            }

            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }
            setVideoFile(file);
            setVideoPreview(URL.createObjectURL(file));
        }
    }, [videoPreview]);

    const handleVideoUpload = async () => {
        if (!videoFile) {
            dispatch({type: 'SET_ERROR', payload: 'Please select a video file'});
            return;
        }

        dispatch({type: 'SET_LOADING', payload: true});
        const formData = new FormData();
        formData.append('video', videoFile);

        try {
            await axiosInstance.post(
                `/api/upload-video/${selectedContentType}`,
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                }
            );

            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }

            setVideoFile(null);
            setVideoPreview(null);

            // Reset content to force reload
            const currentContent = state.contents[selectedContentType];
            dispatch({
                type: 'SET_CONTENT',
                contentType: selectedContentType,
                payload: {
                    ...currentContent,
                    videoUrl: null // Reset video URL to force reload
                }
            });

            setShowSuccessAlert(true);
            setTimeout(() => setShowSuccessAlert(false), 3000);
        } catch (error) {
            dispatch({type: 'SET_ERROR', payload: 'Failed to upload video'});
        } finally {
            dispatch({type: 'SET_LOADING', payload: false});
        }
    };

    const handleNewPlayerBonusSection = async (field, value) => {
        if (state.loading) return;

        switch (field) {
            case 'text':
                await handleTextUpdate('new_player_bonus', value);
                break;
            case 'url':
                await handleUrlUpdate('new_player_bonus', value, false);
                break;
            case 'secondaryUrl':
                await handleUrlUpdate('new_player_bonus', value, true);
                break;
            default:
                break;
        }
    };

    const currentContent = state.contents[selectedContentType] || {text: '', url: '', secondaryUrl: ''};

    return (
        <div className="update-content">
            {state.error && (
                <div className="error-message">
                    {state.error}
                    <button
                        className="close-error"
                        onClick={() => dispatch({type: 'SET_ERROR', payload: null})}
                    >
                        ×
                    </button>
                </div>
            )}

            <h2>Update Bot Content</h2>

            <select
                value={selectedContentType}
                onChange={(e) => setSelectedContentType(e.target.value)}
                className="content-type-select"
                disabled={state.loading}
            >
                {contentTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                ))}
            </select>

            {selectedContentType === 'new_player_bonus' ? (
                <div className="new-player-bonus-section">
                    <TextContentEditor
                        value={currentContent.text || ''}
                        onChange={(newText) => handleNewPlayerBonusSection('text', newText)}
                        disabled={state.loading}
                    />

                    <div className="url-section">
                        <h3>URLs</h3>
                        <div className="url-input-container">
                            <div className="url-input-group">
                                <label>Casino Bonus URL:</label>
                                <input
                                    type="text"
                                    value={currentContent.url || ''}
                                    onChange={(e) => handleNewPlayerBonusSection('url', e.target.value)}
                                    placeholder="Enter Casino Bonus URL"
                                    className="url-input"
                                    disabled={state.loading}
                                />
                            </div>

                            <div className="url-input-group">
                                <label>Sport Bonus URL:</label>
                                <input
                                    type="text"
                                    value={currentContent.secondaryUrl || ''}
                                    onChange={(e) => handleNewPlayerBonusSection('secondaryUrl', e.target.value)}
                                    placeholder="Enter Sport Bonus URL"
                                    className="url-input"
                                    disabled={state.loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <TextContentEditor
                        value={currentContent.text || ''}
                        onChange={(newText) => handleTextUpdate(selectedContentType, newText)}
                        disabled={state.loading}
                    />

                    {sectionsWithUrl.includes(selectedContentType) && (
                        <div className="url-section">
                            <h3>URL</h3>
                            <div className="url-input-container">
                                <div className="url-input-group">
                                    <label>URL:</label>
                                    <input
                                        type="text"
                                        value={currentContent.url || ''}
                                        onChange={(e) => handleUrlUpdate(selectedContentType, e.target.value)}
                                        placeholder="Enter URL"
                                        className="url-input"
                                        disabled={state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <div className="video-section">
                <h3>Video Content</h3>

                {/* Показываем текущее видео */}
                <VideoPreview contentType={selectedContentType}/>

                {/* Форма загрузки нового видео */}
                <div className="upload-section" style={{marginTop: '20px'}}>
                    <p>Upload new video:</p>
                    <input
                        type="file"
                        accept="video/*"
                        onChange={handleVideoChange}
                        disabled={state.loading}
                    />

                    {videoFile && (
                        <div style={{marginTop: '10px'}}>
                            <p>Selected file: {videoFile.name} ({formatFileSize(videoFile.size)})</p>

                            {/* Предпросмотр нового видео */}
                            {videoPreview && (
                                <div style={{marginTop: '10px'}}>
                                    <h4>New Video Preview</h4>
                                    <video
                                        controls
                                        src={videoPreview}
                                        width="300"
                                        style={{
                                            marginTop: '10px',
                                            borderRadius: '4px',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                        }}
                                    />

                                    <button
                                        onClick={handleVideoUpload}
                                        disabled={state.loading}
                                        style={{
                                            marginTop: '10px',
                                            padding: '8px 16px',
                                            backgroundColor: '#4CAF50',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {state.loading ? 'Uploading...' : 'Upload Video'}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <ButtonEditor
                buttons={buttons}
                onChange={setButtons}
                disabled={state.loading}
            />
            <div className="button-container">
                <button
                    onClick={handleButtonsUpdate}
                    className="update-button"
                    disabled={state.loading}
                >
                    {state.loading ? 'Updating Buttons...' : 'Update Buttons'}
                </button>
            </div>

            <ConfirmDialog
                isOpen={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={() => {
                    setShowConfirmDialog(false);
                    if (selectedContentType === 'new_player_bonus') {
                        handleNewPlayerBonusSection('text', currentContent.text);
                        handleNewPlayerBonusSection('url', currentContent.url);
                        handleNewPlayerBonusSection('secondaryUrl', currentContent.secondaryUrl);
                    } else {
                        handleTextUpdate(selectedContentType, currentContent.text);
                        if (sectionsWithUrl.includes(selectedContentType)) {
                            handleUrlUpdate(selectedContentType, currentContent.url);
                        }
                    }
                }}
                title="Confirm Content Update"
                message={
                    <>
                        Are you sure you want to update this content? After updating, please verify the changes in the
                        Telegram Bot.
                        <br/><br/>
                        Section: <strong>{selectedContentType}</strong>
                    </>
                }
                confirmText="Update Content"
            />

            <SuccessAlert
                isVisible={showSuccessAlert}
                message="Content has been successfully updated. Please check the Telegram Bot to verify the changes."
            />

            {state.loading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </div>
    );
}

export default UpdateBot;