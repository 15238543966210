import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

export const firebaseConfig = {
    apiKey: "AIzaSyBvqYJTnbbZYVxmrEPXcMx6yi3lXOs11Bw",
    authDomain: "twinsbet-chat-bot.firebaseapp.com",
    projectId: "twinsbet-chat-bot",
    storageBucket: "twinsbet-chat-bot.firebasestorage.app",
    messagingSenderId: "3198861017",
    appId: "1:3198861017:web:4b6eff01425485b4a108c8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };